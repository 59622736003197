body {
  /* background-image: url('/home/j/Documents/code/Random Projects/for-fun/src/Images/colorBackground.jpg'); */
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}

/* import fonts */

@font-face {
  font-family: 'comfortaa-bold';
  src: local('Comfortaa-Bold'), url('./Components/fonts/Comfortaa-Bold.ttf');
}

@font-face {
  font-family: 'Portillo';
  src: local('Portillo'), url('./Components/fonts/Portillo.otf');
}

@font-face {
  font-family: 'righteous';
  src: local('Righteous-Regular'),
    url('./Components/fonts/Righteous-Regular.ttf');
}
